<template>
  <b-card>
    <template v-if="isMaster || isOwner">
      <b-form-text>
        {{ `หมายเหตุ: % คอมมิชชั่นจะถูกไปคิดจากค่าน้ำที่ถูกหักเรียบร้อยแล้ว` }}
        <br />
        ยกตัวอย่าง แทงรวม = 400<br />ค่าน้ำ (5%) รวม = 20 บาท รางวัลผู้ชนะ =
        400-20 = 380 ค่าคอมฯ (50% จากค่าน้ำ) รวม = 2.5x4 = 10
      </b-form-text>
      <br />
    </template>
    <b-table-simple hover responsive="">
      <b-thead>
        <b-tr>
          <b-th v-if="isMaster || isOwner">{{ $t('agent.name') }}</b-th>
          <b-th>{{ $t('games.name') }}</b-th>
          <b-th class="text-right">ยอดแทงรวม</b-th>
          <b-th>ส่วนต่าง</b-th>
          <b-th>{{ $t('agent.commission') }}</b-th>
          <b-th>{{ $t('agent.af_commission') }}</b-th>
          <b-th class="text-right">{{ $t('agent.net_income') }}</b-th>
        </b-tr>
      </b-thead>
      <template v-if="commissions.length > 0">
        <b-tbody v-for="(commission, index) in commissions" :key="index">
          <b-tr
            v-for="(game, key) in commission.games"
            :key="`${index}/${key}`"
          >
            <b-th
              v-if="key === 0 && (isMaster || isOwner)"
              :rowspan="`${commission.games.length}`"
            >
              <b-link :to="`/agents/detail/${commission.agentId}`">
                {{ commission.agentName || $t('agent.name') }}
              </b-link>
            </b-th>
            <b-th>
              {{ game.gameName }}
            </b-th>
            <b-td class="text-right">
              {{ game.totalBetAmount | currency }}
            </b-td>
            <b-td class="text-right">
              {{ game.totalWinLose | currency }}
            </b-td>
            <b-td>
              {{ game.totalCommissionAgent | currency }}
            </b-td>
            <b-td>
              {{ game.totalAf | currency }}
            </b-td>
            <b-td class="text-right">
              {{ game.totalIncome | currency }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th colspan="5" variant="light" class="text-success text-right">
              {{ $t('agent.net_income') }}
              <span v-if="true">
                {{ commission.total.totalIncome | currency }}
              </span>
            </b-th>
          </b-tr>
        </b-tbody>
      </template>
      <b-tbody v-else>
        <b-tr>
          <b-th colspan="5" class="text-center">
            {{ $t('messages.nothing_here') }}
          </b-th>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'CommissionsProviderGames',
  props: {
    commissions: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    ...mapState(['game']),
    ...mapGetters(['userInfo', 'isMaster', 'isOwner']),
    agentId() {
      return this.userInfo.username
    },
  },
}
</script>
